.back-drop-gradiant {
  width: 100%;
  height: 100vh;
  background: #fffdf2;
  box-sizing: border-box;
  overflow: hidden;
  position: fixed;
  z-index: -1;
}

.circle-wrap {
  position: relative;
  width: 100%;
  height: 100%;
}

.first-circle::before {
  content: "";
  width: 65em;
  height: 65em;
  flex-shrink: 0;
  background: #fdf9e8;
  position: absolute;
  top: -12em;
  border-radius: 0px 0% 100% 0%;
  bottom: 0;
}

.second-circle::before {
  content: "";
  width: 43em;
  height: 38em;
  flex-shrink: 0;
  background: #f9f4d9;
  position: absolute;
  top: -16.5em;
  left: -10em;
  border-radius: 100% 90% 100% 100%;
  bottom: 0;
}

.logo {
  position: absolute;
  display: flex;
  align-items: center;
  top: 8px;
  left: -13px;
}

.logo img {
  width: 280px;
  height: 60px;
}

.w-70 {
  width: 80%;
}

.font-14 {
  font-size: 14px;
}

.container {
  padding-left: 100px;
  padding-right: 20px;
  padding-top: 10px;
}


input:focus,
select:focus,
textarea:focus {
  outline: none;
  border: 1px solid gainsboro;
}

.button:focus {
  outline: none;
}

.p-inputtext:enabled:hover {
  border-color: gainsboro;
}

.bg-color-green {
  background-color: #e0a553;
}

.card-border-color {
  border-radius: 8px;
  border: 1px solid rgba(15, 191, 153, 0.2);
}

.text-red-500 {
  position: absolute;
  top: 80px;
}

/* Button style */
.p-button {
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  border: none;
  background: none;
}

.p-button .pi {
  margin-right: 7px;
}

.orange-btn {
  background-color: #f58500;
  height: 35px;
}

.green-btn {
  background-color: #e0a553;
  height: 35px;
}

.btn {
  background-color: #e0a553;
  height: 43px;
}

.p-datepicker-trigger {
  background-color: #e0a553;
}

.p-button.p-button-warning:enabled:focus {
  box-shadow: none;
}

.p-button:focus {
  box-shadow: none;
}

.p-icon-field .pi {
  top: 15px;
  color: #000;
}

.p-autocomplete-dropdown.p-button {
  background-color: #e0a553;
}

.red-btn {
  height: 35px;
  background-color: rgb(245, 81, 81);
}

.p-button .pi.pi-pencil {
  color: #e0a553;
  padding: 0px;
}

.black-button .p-button-icon {
  color: #000;
  margin: 0px;
  font-size: 14px;
}

.black-button .p-button-rounded {
  border: 1px solid rgb(137, 137, 137);
  margin: 0px 7px;
  padding: 5px;
  width: 35px;
  height: 35px;
}

.black-button .p-button .pi.pi-pencil {
  color: #000;
}

.cancel-btn {
  font-size: 14px;
  font-weight: 300;
  padding: 5px 24px;
  background-color: white;
  color: #000;
  border: 1px solid #e0a553;
}

.cancel-submit-btn .cancel-btn {
  font-size: 14px;
  font-weight: 300;
  padding: 5px 24px;
  background-color: white;
  color: #000;
  border: 1px solid #e0a553;
  height: 35px;
}

.cancel-submit-btn .submit-btn {
  font-size: 14px;
  font-weight: 300;
  padding: 5px 24px;
  background-color: #e0a553;
  color: white;
  height: 35px;

}

.cancel-submit-btn {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.cancel-submit-btn .p-button-label {
  font-weight: 300;
}

.p-selectbutton {
  border-radius: 58px;
  border: 1px solid #929292;
  background: #fff;
  width: 200px;
  padding: 5px;
  height: 42px;
}

.p-selectbutton .p-button {
  border-radius: 20px;
  padding: 8px;
  font-size: 14px;
  width: 50%;
  font-weight: 300 !important;
}

.p-selectbutton .p-highlight {
  background: #e0a553;
  color: white;
}

.p-button-label {
  font-weight: 300;
}

.poi-list {
  color: #000;
  padding: 7px;
  cursor: pointer;
  width: 100%;
}

/* Dropdown */
.p-dropdown {
  width: 100%;
  font-family: var(--font-family);
  font-feature-settings: var(--font-feature-settings, normal);
  font-size: 1rem;
  color: #4b5563;
  background: #ffffff;
  padding: 0.75rem 0.75rem;
  border: 1px solid #d1d5db;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  appearance: none;
  border-radius: 6px;
  outline-color: transparent;
  height: 46px;
}

.p-dropdown-items .p-dropdown-item {
  padding: 10px 15px 10px 15px;
}

.p-datepicker-calendar tr th {
  padding: 15px;
  font-size: 14px;
}

.p-datepicker-calendar tr td {
  font-size: 14px;
}

/* Sidebar icon */

.DashboardIcon {
  background: url("../images/icons/dashboard.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 35px;
  height: 35px;
}

.UsersIcon {
  background: url("../images/icons/person.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 35px;
  height: 35px;
}

.VisitorsIcon {
  background: url("../images/icons/group.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 35px;
  height: 35px;
}

.AreaIcon {
  background: url("../images/icons/area.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 35px;
  height: 35px;
}

.SubAreaIcon {
  background: url("../images/icons/SubArea.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 35px;
  height: 35px;
}

.LocationIcon {
  background: url("../images/icons/location.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 35px;
  height: 35px;
}

.EditDocumentIcon {
  background: url("../images/icons/edit_document.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 35px;
  height: 35px;
}

.ProtocolIcon {
  background: url("../images/icons/protocol.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 35px;
  height: 35px;
}

.ReportIcon {
  background: url("../images/icons/Report.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 35px;
  height: 35px;
}

.Setting {
  background: url("../images/icons/settings.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 35px;
  height: 35px;
}


/* Sidebar style */

.sidebar li:hover .DashboardIcon,
.sidebar .active a .DashboardIcon {
  background: url("../images/icons/dashboard-white.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 35px;
  height: 35px;
}

.sidebar li:hover .UsersIcon,
.sidebar .active a .UsersIcon {
  background: url("../images/icons/person-white.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 35px;
  height: 35px;
}

.sidebar li:hover .VisitorsIcon,
.sidebar .active a .VisitorsIcon {
  background: url("../images/icons/group-white.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 35px;
  height: 35px;
}

.sidebar li:hover .AreaIcon,
.sidebar .active a .AreaIcon {
  background: url("../images/icons/area-white.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 35px;
  height: 35px;
}

.sidebar li:hover .SubAreaIcon,
.sidebar .active a .SubAreaIcon {
  background: url("../images/icons/SubArea.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 35px;
  height: 35px;
}

.sidebar li:hover .LocationIcon,
.sidebar .active a .LocationIcon {
  background: url("../images/icons/location-white.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 35px;
  height: 35px;
}

.sidebar li:hover .EditDocumentIcon,
.sidebar .active a .EditDocumentIcon {
  background: url("../images/icons/edit_document-white.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 35px;
  height: 35px;
}

.sidebar li:hover .ProtocolIcon,
.sidebar .active a .ProtocolIcon {
  background: url("../images/icons/protocol-white.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 35px;
  height: 35px;
}

.sidebar li:hover .ReportIcon,
.sidebar .active a .ReportIcon {
  background: url("../images/icons/Report-white.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 35px;
  height: 35px;
}


.sidebar li:hover .Setting,
.sidebar .active a .Setting {
  background: url("../images/icons/settings-white.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 35px;
  height: 35px;
}

/* Tab section style */
.tab-sectionTable {
  padding: 20px 0px;
  border-radius: 10px;

  position: relative;
}

.tab-sectionTable .p-tabview-nav-container {
  padding: 0px 50px;
  width: max-content;
  margin-bottom: -1px;
}

.p-tabview-nav-content {
  width: max-content;
}

.tab-sectionTable .p-tabview-panels {
  border-top: 1px solid #e0a553;
  height: 70vh;
}

.tab-sectionTable .p-tabview-nav .p-unselectable-text.p-tabview-selected.p-highlight {
  width: auto;
  text-align: center;
  border-top: 1px solid #e0a553;
  border-right: 1px solid #e0a553;

  border-left: 1px solid #e0a553;

  background: #fff;
}

.p-column-filter.p-inputtext.p-component {
  height: 30px !important;
}

.tab-sectionTable .p-tabview .p-tabview-nav .p-tabview-ink-bar {
  z-index: 999;
  display: block;
  position: absolute;
  bottom: 0;
  height: 1px;
  background-color: #ffffff;
  transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
}

.tab-sectionTable .p-tabview-nav .p-unselectable-text {
  width: auto;
  text-align: center;
  border-top: 1px solid #e0a553;
  border-right: 1px solid #e0a553;
  border-left: 1px solid #e0a553;
  border-radius: 8px 8px 0px 0px;
}

.tab-sectionTable .p-tabview-nav .p-unselectable-text.p-tabview-selected.p-highlight a {
  color: white;
  text-align: center;
  height: 40px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding: 0px 20px;
  margin: 0px auto;
  width: 100%;
  background-color: #e0a553;
  margin-right: 5px;
}

.tab-sectionTable .p-tabview-nav li a {
  color: #e0a553;
  text-align: center;
  height: 40px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding: 0px 20px;
  margin: 0px auto;
  width: 100%;
  border-radius: 7px 7px 0px 0px;
  background-color: white;
  margin-right: 5px;
}

.tab-sectionTable .search-box {
  position: absolute;
  right: 20px;
  top: 15px;
}

.search-box .p-inputtext {
  font-size: 14px;
  width: 100%;
  min-width: 500px;
}

/* Dashboard style */
.dashboard-card .counter {
  color: #000;
  text-align: center;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 53px;
}

.dashboard-card p {
  color: #000;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.dashboard-card .border {
  border-radius: 16px;
}

.area-card {
  border-radius: 16px;
  min-height: 100px;
}

.area-card .col-4 {
  border-radius: 8px 0px 0px 8px;
  background: rgb(224, 165, 83);
}

.area-card .col-8 {
  padding: 5px 10px;
}

.area-card .col-8 .area-count {
  color: #000;
  font-size: 28px;
  font-weight: 300;
  line-height: 34px;
  text-align: left;
  width: 100%;
}

.area-card .col-8 p {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  /* 19.306px */
  text-align: left;
}

.dashboard-card h3 {
  color: #000;
  text-align: center;

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  /* 24.822px */
}

.map {
  border-radius: 16px;
}

.map {
  width: 100%;
  height: 100%;
  border: 1px solid #b8b8b8c2;
}

.flex.dashboard-card.map-section {
  height: 650px;
}

ul li {
  font-size: 12px;
  line-height: 15px;
}

.font-12 {
  font-size: 12px;
  line-height: 15px;
}

.card-section {
  border-radius: 16px;
  background-color: #fff;
  width: 100%;
  padding: 5px;
  height: 80vh;
}

.vh-70 {
  height: 70vh;
}

.footer-button {
  position: absolute;
  right: 30px;
  bottom: 10px;
}

/* Table layout style */

.p-datatable .p-datatable-header {
  background-color: white;
  border-radius: 16px 16px 0px 0px;
}

.p-datatable .p-paginator-bottom {
  border-radius: 0px 0px 16px 16px;
}

.p-datatable-scrollable>.p-datatable-wrapper {
  border-radius: 0px 0px 16px 16px;
}

.table-section .p-datatable .p-datatable-tbody>tr>td {
  text-align: left;
  border: 1px solid #e5e7eb;
  border-width: 0 0 1px 0;
  padding: 5px 10px;
}

.p-datatable .p-datatable-loading-icon.p-icon {
  color: #e0a553;
}

.p-component-overlay {
  background: rgba(255, 255, 255, 0.6);
}

.table-section .p-datatable .p-datatable-thead>tr>th {
  text-align: left;
  padding: 5px 10px;
  font-size: 16px;
  border: 1px solid #e5e7eb;
  border-width: 0 0 1px 0;
  font-weight: 400;
  color: #000000;
  background: #f9f9f9;
  transition: box-shadow 0.2s;
}

.p-datatable-thead tr th {
  padding: 7px 10px !important;
}

.video-style {
  width: 200px;
  height: 150px;
  display: flex;
  margin-left: 20px;
}

.headingWithBackButton .card-heading {  
  padding: 7px;
}

.headingWithBackButton .card-heading h5 {
  font-size: 18px;
  line-height: 21px;
}

.table-option {
  padding: 5px 7px;
  border-radius: 44px;
  background: #6be2c9;
  margin: 3px;
  color: rgb(0, 0, 0);
  font-size: 14px;
}

.p-datatable .p-datatable-tbody>tr>td .p-row-toggler {
  background-color: #d5f2eb;
}

.unexpand .p-row-toggler.p-link {
  transform: rotate(90deg);
}

.expand-row .p-row-toggler.p-link {
  transform: rotate(180deg);
}

.p-component-overlay {
  width: 100%;
  height: 70vh;
}

.transparant-table {
  padding: 5px;
  height: 83vh;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #fff;
  background: rgba(255, 255, 255, 0.6);
}

.transparant-table .table-section .p-datatable .p-datatable-thead>tr>th {
  background: transparent;
}

.transparant-table .p-datatable .p-datatable-tbody>tr,
.transparant-table .p-datatable.p-datatable-scrollable>.p-datatable-wrapper>.p-datatable-table>.p-datatable-thead {
  background: transparent;
}

.popup label {
  font-size: 16px;
  line-height: 19px;
}

/* Icon import */

.custome-icon {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  width: 14px;
  height: 14px;
  margin: 0px 7px;
}

.edit-icon {
  background-image: url("../images/icons/edit-icon.svg");
}

.delete-icon {
  background-image: url("../images/icons/delete-icon.svg");
}
.view-icon {
  background-image: url("../images/icons/view.svg");
}
.editform-icon {
  background-image: url("../images/icons/editform-icon.svg");
  margin-bottom: 8px;
}

/* Popup  Style*/
.popup-layout {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 14313;
  pointer-events: auto;
  background-color: rgba(0, 0, 0, 0.356);
}

.p-dialog-header .p-dialog-header-icons {
  display: none;
}

.popup {
  background-color: white;
  border-radius: 8px;
}

.p-multiselect-panel.p-component.p-ripple-disabled.p-connected-overlay-enter-done {
  z-index: 99999 !important;
}

.p-dropdown-panel.p-connected-overlay-enter-done {
  z-index: 99999 !important;
}

.popup .p-dropdown {
  height: 40px;
}

.pop-up-close .p-dialog-header .p-dialog-header-icons {
  display: block;
}

.pop-up-close .p-dialog-content {
  display: flex;
  justify-content: center;
}
.pop-up-close-treatement .p-dialog-header .p-dialog-header-icons {
  display: block !important;
  width: "80vw" !important;
}

.pop-up-close-treatement .p-dialog-content {
  display: flex;
  justify-content: center;
}
.container .p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0.375rem 0.75rem;
  margin-right: 0.5rem;
  color: #000;
  border-radius: 44px;
  background: #e0a553;
}

.p-icon.p-multiselect-token-icon path {
  background: #000;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight.p-focus,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus {
  background: #f7e8d4 !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus {
  color: #000000;
  background: #f7e8d4 !important;
}

.p-checkbox.p-highlight .p-checkbox-box {
  border-color: #e0a553;
  background: #e0a553;
}

.popup .p-inputtext,
.popup .p-multiselect {
  height: 40px;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #000000;
  background: #f7e8d4 !important;
}

.popup .p-inputtextarea.p-inputtext {
  height: auto;
}

.p-confirm-dialog-accept.p-button.p-component {
  background-color: #e0a553;
}

.p-confirm-dialog-reject.p-button.p-component {
  color: #000;
  border: 1px solid #929292;
}

.bg-color {
  background-color: #e0a553;
}

.brows-file {
  height: 40px;
  width: 80px;
}

#file-size {
  font-size: 12px;
  color: #929292;
  font-weight: 400;
}

.comingsoon {
  height: 80vh;
  flex: 100%;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

.accordion-header {
  display: none;
}

.p-button.p-button-loading-label-only .p-button-loading-icon {
  position: absolute;
  left: 4px;
  margin: 0 auto;
}

.p-treenode-label {
  color: #000;
  width: 100%;
}

.login-card .text-red-500 {
  position: absolute;
  bottom: 0px;
  top: auto;
}

/* POI-Layout */
.poicard {
  height: 83vh;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #fff;
  background: white;
}

.card {
  border-radius: 9px;
  border: 1px solid #fff;
}

.inputform {
  border-radius: 8px;
  border: 1px solid #929292;
  background: #fff;
  height: 34px;
  margin-left: 60px;
}



.char-inputform {
  border-radius: 8px;
  border: 1px solid #929292;
  background: #fff;
  height: 30px;
}

.place-inputform {
  border-radius: 8px;
  border: 1px solid #929292;
  background: #fff;
  height: 30px;
  margin-left: 10px;
}

.disable {
  border-radius: 8px;
  border: 1px solid #929292;
  background: #d9d9d9;
}

.tab-cmssection {
  padding: 20px 0px;
  border-radius: 10px;
  background-color: white;
  position: relative;
  border-radius: 8px 8px 0px 0px;
  border: 1px solid #d5f2eb;
  background: #f4f4f4;
}

.fileinput {
  display: flex;
  height: 35px;
  align-items: center;
  width: 200px;
  margin-left: 50px;
  border-radius: 6px 0px 0px 6px;
}

.fileinamenput {
  display: flex;
  height: 35px;
  align-items: center;
  width: 200px;
  margin-left: 50px;
}

.file-upload.fileselectbtn {
  border-radius: 0px 8px 8px 0px;
  border: 1px solid #929292;
  background: #e0a553;
  height: auto;

}

.fileselectbtn {
  border-radius: 0px 8px 8px 0px;
  border: 1px solid #929292;
  background: #e0a553;
  height: 35px;
}

.fileselectbtn svg.p-icon {
  display: none;
}

.sub-area.p-accordion-header {
  padding: 0px 10px;
  border: none;
}

.scrollable-content {
  max-height: 600px;
  /* Adjust the height as needed */
  overflow-y: auto;
  /* Enables vertical scrolling */
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: #d5f2eb #f9f9f9;
}

.scrollable-content-tab {
  height: 100%;
  max-height: 400px;
  /* Adjust the height as needed */
  overflow-y: auto;
  /* Enables vertical scrolling */
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: #d5f2eb #f9f9f9;
}

.sub-area .p-accordion .p-accordion-content {
  border: none;
}

.scrollable-content .p-tree {
  padding: 10px;
}

.scrollable-content .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  height: 20px;
}

.scrollable-content .p-treenode-label {
  font-size: 15px;
  line-height: 19px;
  font-weight: 500;
  padding: 7px 0px;
  border-bottom: 1px solid gainsboro;
}

.p-tree .p-tree-container .p-treenode:focus>.p-treenode-content {
  box-shadow: none;
}

.p-tree .p-treenode-children {
  padding: 0 0 0 10px;
}

.image-style img {
  width: 100px;
  height: 100px;
}

.tab-scrollable-content {
  max-height: 550px;
  /* Adjust the height as needed */
  overflow-y: auto;
  /* Enables vertical scrolling */
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: #d5f2eb #f9f9f9;
  padding-right: 10px;
}

.poi-button {
  background: transparent;
  color: black;
  padding: 7px 10px;
  width: 100%;
  text-align: left;
}

.poi-button:hover {
  background-color: #80f8de27;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
  color: #374151;
  border-color: transparent;
  background: none;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus-visible {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.physiology {
  height: 100px;
  width: 800px;
  margin-left: -190px;
}

.gallery {
  height: 250px;
}

.progress-spinner .p-progress-spinner-circle {
  stroke-dasharray: 89, 200;
  stroke-dashoffset: 0;
  stroke: #e0a553 !important;
  stroke-linecap: round;
}

.progress-spinner .p-progress-spinner {
  display: flex;
  align-items: center;
  height: 80vh !important;
}

.cms-layout .p-progress-spinner-circle {
  stroke-dasharray: 89, 200;
  stroke-dashoffset: 0;
  stroke: #e0a553 !important;
  stroke-linecap: round;
}

.file-upload-spinner .p-progress-spinner-circle {
  stroke-dasharray: 89, 200;
  stroke-dashoffset: 0;
  stroke: #e0a553 !important;
  stroke-linecap: round;
}

.cms-layout .p-progress-spinner {
  display: flex;
  align-items: center;
  height: 80vh !important;
}

.cms-layout .tab-scrollable-content .p-progress-spinner {
  height: 55vh !important;
}

.tab-scrollable-content .p-progress-spinner-svg {
  width: 70%;
  height: 50%;

}

.poitemplate {
  height: 150px;
  width: 800px;
  margin-left: -190px;
}

.select-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  font-size: 30px;
  color: #E0A553;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content {
  padding: 0px 0px;
}

.p-tree .p-tree-container .p-treenode {
  padding: 0px;
}

.p-accordion-header-text {
  border-bottom: 1px solid #d9d9d9;
  background: #fff;
  color: black;
  width: 100%;
  font-weight: 500;
  padding: 7px 0px;
}

.tab-carousel {
  width: 600px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid gainsboro;
}

.tab-carousel .p-carousel {
  padding: 10px;
}

.p-carousel .p-carousel-indicators {
  display: none;
}


.p-inputtextarea.p-inputtext.p-component {
  overflow-y: scroll !important;
}


/* Customer card  */
.customer-card.col {
  max-width: 400px;
  min-width: 350px;
  border-radius: 16px;
  border: 1px solid #e0a553;
  padding: 0px;
  height: 85vh;
  max-height: 85vh;
  overflow: hidden;
}


.customer-card-body {
  background-color: #fff;
  border-radius: 0px 0px 16px 16px;
  height: 100%;
}

.customer-info ul {
  display: flex;
}

.customer-card-header {
  border-radius: 16px 16px 0px 0px;
  border-bottom: 1px solid #e0a553;
  padding: 15px;
  background: #f7e8d4;
  position: relative;

}

.customer-card-body {
  padding: 15px;
}

.basic-info-tab {
  background-color: rgb(255, 255, 255);
  padding: 5px;
  border-radius: 10px 10px 0px 0px;
  position: absolute;
  bottom: -1px;
  left: 20px;
  border: 1px solid #BEDDBE;
  color: #000;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 137.9%;
}


.customer-info {
  align-items: flex-start;
}

.customer-info ul {
  padding: 0px;
  margin: 0px 0px 10px 0px;

}

.customer-info ul li {
  margin-bottom: 5px;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  white-space: initial;
  word-wrap: break-word;
  text-wrap: wrap;
  width: 50%;
  /* 214.286% */
}

.customer-card-header p {
  font-size: 12px;
  line-height: 17px;
}

.customer-card-header span {
  font-size: 12px;
  line-height: 17px;
}

.border-16 {
  border-radius: 16px;
}

.h-85 {
  height: 84vh;
}

.blank-input-text {
  border: none;
  border-bottom: 1px solid #929292;
  border-radius: 0px;
}

.p-dropdown:not(.p-disabled):hover,
.p-dropdown:not(.p-disabled).p-focus {
  border-color: none;
  box-shadow: none;
}

.blank-input-text:focus,
.blank-input-text:hover {
  border: none;
  border-bottom: 1px solid #929292;
}


.treatment-tab {
  border-radius: 16px;
  border: 1px solid #BEDDBE;
  height: 57vh;
}

.toggle-button {
  background-color: #F4FAFA;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 30px;
}

.toggle-menu {
  border-radius: 30px;
  border: 1px solid #D5F2EB;
  background: #F4FAFA;
}

.toggle-button.active {
  border-radius: 30px;
  background: #e0a553;
  color: white;
}

.toggle-section {
  display: none;
}

.toggle-section.active {
  display: block;
}

.treatment-tab .p-tabview {
  display: flex;
  align-items: center;
}

.treatment-tab .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  width: auto;
  text-align: center;
  background: #e0a553;
  color: white;
  font-size: 12px;
  padding: 10px;
  border-radius: 30px;
}

.treatment-tab .p-tabview .p-tabview-nav li .p-tabview-nav-link {
  width: auto;
  text-align: center;
  background: #FFFFFF;
  color: rgb(0, 0, 0);
  font-size: 14px;
  font-weight: 400;
  padding: 10px 30px;
  border-radius: 30px;
}

.treatment-tab .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  width: auto;
  text-align: center;
  border-radius: 30px;
  padding: 10px 30px;
  height: 100%;
}

.treatment-tab .p-tabview .p-tabview-nav-btn.p-link {
  display: none;
}

.treatment-tab .p-tabview .p-tabview-nav .p-tabview-ink-bar {
  display: none;
}

.treatment-tab .p-tabview-nav {
  border-radius: 30px;
  border: 1px solid #e0a553;
  background-color: #FFFFFF;
}

.text-red {
  color: red;
}

.poi-description .p-inputtextarea.p-inputtext.p-component {
  height: 150px !important;
  overflow-y: scroll !important;
}

.p-tabview .p-tabview-nav-btn.p-link {
  background-color: transparent;
  display: flex;
  align-items: center;
  color: #e0a553;
}

.p-tabview-nav-content {
  overflow-x: auto;
  overflow-y: hidden;
}

.tab-sectionTable .p-component-overlay {
  height: 40vh;
}

.audio-style {
  width: 250px;
  height: 40px;
}

.audio-layout {
  margin-left: 30px;
  margin-top: 30px;
  width: 800px;
  overflow: hidden;
  overflow-x: auto;
}

.audio-layout span {
  font-size: 14px;
}

.video-layout {
  width: 800px;
  overflow: hidden;
  overflow-x: auto;
  margin-left: 30px;
  margin-top: 30px;
  padding-bottom: 10px;
}


.image-layout {
  width: 800px;
  overflow: hidden;
  overflow-x: auto;
  margin-top: 30px;
}

.poi-spinner-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80%;
}

.p-dialog-content {
  position: relative;
}

.card-wrapper {
  padding: 15px;
  background: #ffff;
  margin-bottom: 10px;
  border-radius: 8px;
}

.popup-card-layout .p-dialog-header {
  background-color: #fdf9e8;
}

.popup-card-layout .p-dialog-content {
  background-color: #fdf9e8;
}

.green-tick-add {
  padding: 0px 20px;
}

.green-tick-add li {
  position: relative;
  margin-bottom: 5px;
}

.green-tick-add li::before {
  content: "";
  display: flex;
  background: url("../images/icons/green-tick.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 14px;
  height: 14px;
  position: absolute;
  left: -20px;
}

.error-msg {
  position: absolute;
  font-size: 12px;
  color: red;
  margin-top: 2px;
}

.popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  color: black;
}

.image-wrapper {
  position: relative;
}

.image-wrapper .icon-delete {
  background-color: white;
  color: #000;
  width: 7px;
  height: 7px;
  position: absolute;
  right: 0px;
  top: 1px;
  z-index: 1;
}

.image-wrapper .icon-delete .pi {
  font-size: 12px;
  margin-right: 0px;
}

video::-webkit-media-controls-enclosure {
  overflow: hidden;
}

video::-webkit-media-controls-panel {
  display: flex !important;
}

video::-webkit-media-controls-play-button,
video::-webkit-media-controls-current-time-display,
video::-webkit-media-controls-time-remaining-display,
video::-webkit-media-controls-seek-back-button,
video::-webkit-media-controls-seek-forward-button,
video::-webkit-media-controls-mute-button,
video::-webkit-media-controls-volume-slider,
video::-webkit-media-controls-fullscreen-button,
video::-webkit-media-controls-timeline {
  display: inline-flex !important;
}

video::-webkit-media-controls-menu-button {
  display: none !important;
}

/* Assuming the button has a class 'more-options' */
.more-options {
  display: none !important;
}

.headingWithBackButton {
  height: 50px;
}

.cms-layout .tab-scrollable-content .image-wrapper .p-progress-spinner {
  height: 10vh !important;
  position: absolute;
  left: 10px;
  top: -10px
}

.tab-scrollable-content .image-wrapper .p-progress-spinner-svg {
  width: 50%;
  height: 40%;
}

.tab-sectionTable .p-tabview-nav-content {
  width: 900px;
}


/* Setting Menus */
.setting-menus {
  height: 80vh;
  padding: 25px 0px;
  margin-left: -18px;
  margin-top: -10px;
  background-color: #ffffff80;
  border: none;
  border-radius: 0px;
  max-width: 250px;
  width: 200px;
}

.setting-menus .p-menuitem-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.setting-menus .p-menuitem {
  margin-bottom: 10px;
}

.setting-menus .p-menuitem-content {
  padding: 7px 0px 7px 20px;
}

.setting-menus .p-menuitem .p-menuitem-content:hover {
  color: #4b5563;
  background: #f7e8d4;
}

.setting-menus .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content {
  color: #4b5563;
  background: none;
}

.p-inputswitch.p-highlight .p-inputswitch-slider {
  background-color: #e0a553;
}

.setting-menus .active.p-menuitem .p-menuitem-content:hover {
  background-color: #e0a553;
  color: white;
}

.setting-menus .active.p-menuitem {
  background-color: #e0a553;
  color: white;
}

.p-column-filter-clear-button {
  display: none;
}

.gray-bg {
  background-color: #F9F9F9;
  padding: 10px;
}

.w-120 {
  width: 120px;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.4;
}

.text-area {
  min-height: 200px;
  max-height: 200px;
}

.text-area-red-bottom {
  position: absolute;
  bottom: -10;
  color: red;
}

.text-area-red-does-donts-bottom {
  position: absolute;
  color: red;
}

.file-upload .fileselectbtn
 .p-datatable .p-datatable-tbody>tr>td .p-row-toggler {
   background-color:white !important;
}

.treament-history-row .med-text{
  color: #e0a553 !important;
  font-size: medium;
}
.treament-history-row .p-datatable-scrollable>.p-datatable-wrapper {
  border-radius:  0px !important;
  overflow: hidden !important;
}
.custom-sidebar {
  width: 700px; 
}

.inputbox:disabled {
  color: #000000;  
  background-color: #e8e4e4;  
  cursor: not-allowed;  
  opacity: 1;
}
.p-inputtext.p-disabled {
  color: #000000;  
  background-color: #e8e4e4;  
  cursor: not-allowed;  
  opacity: 1;
}
.p-dropdown.p-disabled {
  color: #000000;  
  background-color: #e8e4e4;  
  cursor: not-allowed;  
  opacity: 1;
}


.small-input {
  width: 150px; 
  padding: 5px; 
}

.small-dropdown {
  width: 150px; 
  padding: 5px; 
  margin-left: 5px;
  vertical-align: middle;
  line-height: 30px; 
  vertical-align: middle;

}
.sticky-header-table .p-datatable-thead > tr > th {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #ffffff; /* or any other background color that matches your design */
}

.custom-progress-bar .p-progressbar-value {
  background-color: #ba3f1d; 
  margin-bottom: "0";
}
/* Styles for dynamic form fields specific to this popup */
.view-popup-field {
  display: flex;
  align-items: center; /* Vertically center align the label and input */
  margin-bottom: 1rem; /* Space between each field */
}

.view-popup-field label {
  margin-right: 5px; /* Adjust the space between the label and the input */
  width: 150px; /* Fixed width for consistent label alignment */
  font-size: 1rem;
  font-weight: bold;
}

.view-popup-field input {
  width: 300px !important; /* Force width using !important */
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  margin-right:  16px;
}

.mediadata-card {
  width: 100%;
  max-width: 3000px; 
  height: auto; 
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.mediacard-tab-section {
  margin-top: 70px;
}

.mediacard-tab-section .tab-sectionTable .p-tabview-nav-content
{
  width: fit-content;
}
.play-icon {
  background-image: url("../images/icons/play.svg");
  background-size: contain; 
  background-repeat: no-repeat; 
  width: 30px; 
  height: 20px; 
  display: inline-block; 
}
.center-div {
  display: flex;
  justify-content: center; 
  align-items: center;
  height: 100%; 
  margin-top: 50px;
}

@media (min-width: 768px) {
  .view-popup-field {
    flex-direction: row;
    align-items: center;
  }

  .view-popup-field label {
    width: 200px;
    margin-bottom: 0; 
    margin-right: 1rem; 
  }

  .view-popup-field input {
    flex: 1;
  }
}



@media only screen and (max-width: 1600px) {
  .first-circle::before {
    width: 55em;
    height: 55em;
    top: -10em;
  }

  .second-circle::before {
    width: 40em;
    height: 32em;
    top: -17.5em;
    left: -10em;
    border-radius: 100% 90% 100% 100%;
    bottom: 0;
  }

  .logo {
    top: 10px;
    left: 0px;
  }

  .logo img {
    width: 250px;
    height: 50px;
  }

  .profile-brows .brows-file {
    height: auto;
  }

  .dashboard-card .counter {
    font-size: 34px;
  }

  .dashboard-card p {
    font-size: 16px;
  }

  .dashboard-card .h-7rem {
    height: 6rem !important;
  }

  .dashboard-card .w-19rem {
    width: 14rem !important;
  }

  .area-card .col-8 .area-count {
    font-size: 18px;
    line-height: 22px;
  }

  .area-card .col-8 p {
    font-size: 12px;
    line-height: 15px;
  }

  .flex.dashboard-card.map-section {
    height: 500px;
  }

  .DashboardIcon {
    width: 30px;
    height: 30px;
  }

  .UsersIcon {
    width: 30px;
    height: 30px;
  }

  .VisitorsIcon {
    width: 30px;
    height: 30px;
  }

  .AreaIcon {
    width: 30px;
    height: 30px;
  }

  .SubAreaIcon {
    width: 30px;
    height: 30px;
  }

  .LocationIcon {
    width: 30px;
    height: 30px;
  }

  .EditDocumentIcon {
    width: 30px;
    height: 30px;
  }

  .ProtocolIcon {
    width: 30px;
    height: 30px;
  }

  
  .ReportIcon {
    width: 30px;
    height: 30px;
  }

  .Setting {
    width: 30px;
    height: 30px;
  }

  /* Sidebar style */

  .sidebar li:hover .DashboardIcon,
  .sidebar .active a .DashboardIcon {
    width: 30px;
    height: 30px;
  }

  .sidebar li:hover .UsersIcon,
  .sidebar .active a .UsersIcon {
    width: 30px;
    height: 30px;
  }

  .sidebar li:hover .VisitorsIcon,
  .sidebar .active a .VisitorsIcon {
    width: 30px;
    height: 30px;
  }

  .sidebar li:hover .AreaIcon,
  .sidebar .active a .AreaIcon {
    width: 30px;
    height: 30px;
  }

  .sidebar li:hover .SubAreaIcon,
  .sidebar .active a .SubAreaIcon {
    width: 30px;
    height: 30px;
  }

  .sidebar li:hover .LocationIcon,
  .sidebar .active a .LocationIcon {
    width: 30px;
    height: 30px;
  }

  .sidebar li:hover .EditDocumentIcon,
  .sidebar .active a .EditDocumentIcon {
    width: 30px;
    height: 30px;
  }

  .sidebar li:hover .ProtocolIcon,
  .sidebar .active a .ProtocolIcon {
    width: 30px;
    height: 30px;
  }

  .sidebar li:hover .ReportIcon,
  .sidebar .active a .ReportIcon {
    width: 30px;
    height: 30px;
  }

  .sidebar li:hover .Setting,
  .sidebar .active a .Setting {
    width: 30px;
    height: 30px;
  }

  .poicard {
    height: 80vh;
  }

  .poicard .scrollable-content {
    max-height: 450px;
    /* Adjust the height as needed */
    overflow-y: auto;
    /* Enables vertical scrolling */
    scrollbar-width: thin;
    /* For Firefox */
    scrollbar-color: #d5f2eb #f9f9f9;
  }

  .tab-sectionTable .tab-scrollable-content {
    max-height: 440px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #d5f2eb #f9f9f9;
    padding-right: 10px;
    width: 100%;
  }

  .cms-layout .transparant-table {
    height: 80vh;
  }

  .tab-sectionTable .p-tabview-nav-content {
    width: 800px;
  }

  .table-section .p-datatable .p-datatable-tbody>tr>td {
    font-size: 14px;
  }

  .poi-flex .col {
    display: flex;
    flex-direction: column;
  }

  .scrollable-content-tab {
    height: 100%;
    max-height: 290px;
    /* Adjust the height as needed */
    overflow-y: auto;
    /* Enables vertical scrolling */
    scrollbar-width: thin;
    /* For Firefox */
    scrollbar-color: #d5f2eb #f9f9f9;
  }

  .treatment-tab {
    border-radius: 16px;
    border: 1px solid #BEDDBE;
    height: 50vh;
  }
}



@media screen and (min-width: 768px) {
  .scrollable-content .md\:w-30rem {
    width: 100% !important;
  }
  .pop-up-close-treatement .p-dialog-header .p-dialog-header-icons {
    width: "80vw" !important;
  }
}

.no-margin .p-button-icon.p-c.pi.pi-plus{
  margin-right: 0px !important;
}

.form-card{
  background-color: white;
  padding: 10px;
  border-radius: 10px;
}