.login-info h2 {
  color: #000;
  font-weight: 700;
  font-style: normal;
  font-size: 24px;

  line-height: normal;
  margin-bottom: 20px;
}

.login-info p {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
}
.login-form .login-button.p-button {
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  gap: 10px;
  width: 90px;
  border-radius: 4px;
  background: #e0a553;
  border: none;
}
.login-button .p-button-label {
  font-weight: 400;
}

.login-form h5 {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 15px;
}

.login-card {
  background-color: #ffffff;
  border: 1px solid #929292;
  border-radius: 16px;
  padding: 19px 30px;
  min-width: 500px;
  max-width: 500px;
  min-height: 400px;
  max-height: 700px;
}
.login-card .block {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  margin-bottom: 5px;
}
.login-card input {
  border: none;
  height: 40px;
  border-radius: 6px;
  padding: 10px;
  border: 1px solid #929292;
}

.forget-password h5 {
  margin-bottom: 46px;
}
.forget-password-input {
  margin-bottom: 60px;
}
.forget-password p,
.update-password p,
.forget-password a,
.update-password a {
  color: #e0a553;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  margin-bottom: 20px;
}
.custom-otp-input {
  width: 40px;
  font-size: 36px;
  border: 0 none;
  appearance: none;
  text-align: center;
  transition: all 0.2s;
  background: transparent;
  border-bottom: 2px solid var(--surface-500);
}

.custom-otp-input:focus {
  outline: 0 none;
  border-bottom-color: var(--primary-color);
}

.password-check input {
  border: none;
  border-radius: 0px;
  margin-bottom: 5px;
  height: 25px;
}
.password-strength {
  display: none;
}

.password-check > div.active .password-strength {
 display: block;
}
.password-check > div.active .Poor {
  border-bottom: 2px solid #f00;
}
.password-check  > div.active  .Normal {
  border-bottom: 2px solid #ff8a01;
}
.p-inputtext:enabled:focus {
  box-shadow: none;
  background-color: none;
  border-color: 1px solid gainsboro;
}
.password-check > div.active .Strong {
  border-bottom: 2px solid #0fbf99;
}
@media only screen and (max-width: 1600px) {
  .login-info h2 {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .login-info p {
    font-size: 16px;

    margin-bottom: 15px;
  }

  .login-form h5 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .forget-password h5 {
    margin-bottom: 50px;
  }
  .forget-password h5 {
    margin-bottom: 46px;
  }
  .forget-password-input {
    margin-bottom: 70px;
  }
  .login-card .block {
    font-size: 14px;
  }
  .login-card {
    padding: 19px 30px;
  }
  .login-button .p-button-label {
    font-size: 16px;
  }
  .forget-password p,
  .update-password p,
  .forget-password a,
  .update-password a {
    font-size: 14px;
    margin-bottom: 17px;
    line-height: 13px;
  }
}
