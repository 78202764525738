.navbar {
  background: transparent;
  display: flex;
  flex: 0 20%;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 20px;
  margin-left: 20px;
  height: 80px;
  border-bottom: 1px solid gainsboro;
}

.navbar button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: transparent;
  border: none;
  margin-left: 7px;
}

.navbar-icon {
  width: 35px;
  height: 35px;
}

.userProfile .profile-dropdown {
  visibility: hidden;
  background-color: #ffffff;
  border-radius: 6px;
  position: absolute;
  padding: 10px;
  z-index: 9999;
  top: 50px;
  left: 20px;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  border: 1px solid gainsboro;
}

.profile-dropdown::before {
  content: "";
  position: absolute;
  top: -11px;
  left: 50%;
  margin-right: -5px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent #ffffff transparent;
}

.userProfile:hover .profile-dropdown {
  visibility: visible;
  opacity: 1;
}

.profile-dropdown ul {
  width: 100%;
  padding: 0px;
}

.profile-hover:hover .profile-dropdown {
  visibility: visible;
}

.profile-dropdown ul li {
  margin-bottom: 10px;
}

.profile-dropdown ul a {
  font-size: 14px;
  line-height: 19px;
  color: black;
  text-decoration: none;
}

.userProfile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.welcome-text {
  font-size: 16px;
  font-weight: 700;
}

.logo-wrapper button {
  background: none;
  border: none;
}

.logo-wrapper button:focus-visible {
  outline: none;
}

@media only screen and (max-width: 1600px) {
  .navbar {
    height: 70px;
  }

  .navbar-icon {
    width: 32px;
    height: 32px;
  }
}