.sidebar {
  width: 82px;
  height: 100%;
  background-color: #fdf9e8;
  position: fixed;
  left: 0px;
  bottom: -80px;
  padding: 0px 0px;
  border-radius: 0px;
}

.sidebar ul {
  padding: 0px;
  display: flex;
  margin: 0px;
  flex-direction: column;

}

.sidebar ul li a {
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #000;
  text-decoration: none;
  font-size: 12px;
  padding: 10px 0px;
}



.sidebar li.active {
  background-color: #BA3F1D;
}

.sidebar li:hover {
  background-color: #BA3F1D;
}

.sidebar li:hover span {
  color: white;
}

.active span {
  color: white;
}

@media only screen and (max-width: 1600px) {
  .sidebar {
    bottom: -70px;
  }
}